/* * * * * * * * * * * * * * * * * * * * 
* To collapse all press sequentially:  *
* cmd/ctrl + k                         *
* cmd/ctrl + 1                         *
* * * * * * * * * * * * * * * * * * * */

.imageContainer > ul {
  white-space: nowrap;
}

.imageContainer > ul > li {
  float: left;
  display: inline;
  padding: 0px 5px;
}

.uploadedFiles > li {
  list-style-type: disc;
  display: list-item;
}

.carouselPlayer {
  width: 600px;
}

.carouselPlayer > .carousel-inner > .carousel-item {
  height: 580px;
}

.carouselPlayer .carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
  height: 580px;
}

.carouselPlayer .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #666;
  text-align: center;
}

.carouselPlayer > .carousel-inner > .carousel-item > .carousel-caption > p {
  font-weight: bold;
}

.carouselPlayer .carousel-inner > .active,
.carouselPlayer .carousel-inner > .carousel-item-next,
.carouselPlayer .carousel-inner > .carousel-item-right,
.carouselPlayer .carousel-inner > .carousel-item-prev,
.carouselPlayer .carousel-inner > .carousel-item-left {
  display: flex !important;
}


/*Bottom Indicator with Image*/
.carouselPlayer .carousel-indicators {
  display: -webkit-inline-box;
  margin-left: 0;
  overflow: hidden;
  position: static;
  top: 0;
  white-space: nowrap;
  width: 100%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.carouselPlayer .carousel-indicators .active {
  background-color: #fff;
}

.carouselPlayer .carousel-indicators {
  padding: 1px 0 0 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  bottom: auto;
  left: auto;
  position: relative;
  text-align: left;
  overflow-x: auto;
}

.carouselPlayer .carousel-indicators li {
  position: relative;
  height: 60px;
  width: 80px;
  cursor: pointer;
  text-align: center;
  display: inline;
  border-radius: 0;
  border: 0;
  text-indent: 0;
  vertical-align: top;
  margin: 0;
  margin-right: 1px;
  left: 0;
  top: 0;
}

.carouselPlayer .carousel-indicators li.active:before {
  height: 60px;
  width: 80px;
  content: " ";
  border: 3px solid #99999e;
  position: absolute;
  left: 0;
  top: -20;
  z-index: 100;
}

.carouselPlayer .carousel-indicators img {
  height: 60px;
  position: relative;
  width: 80px;
}
/*END OF Bottom Indicator with Image*/
